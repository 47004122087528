export default [
  {
    label: 'User',
    field: 'user',
  },
  {
    label: 'Wallet name',
    field: 'wallet',
  },
  {
    label: 'Asset',
    field: 'asset',
  },
  {
    label: 'Amount',
    field: 'amount',
  },
  {
    label: 'Address',
    field: 'checkUrl',
  },
  {
    label: 'Time',
    field: 'dataCreated',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
